import { USER_LOGOUT_SUCCESS, USER_LOGOUT_FAILURE } from "../../constants/index";
import { deleteApi } from "../../../utils/apiCall";

export const logoutSuccess = (data) => {
  return {
    type: USER_LOGOUT_SUCCESS,
    payload: data,
  };
};

export const logoutFailure = (error) => {
  return {
    type: USER_LOGOUT_FAILURE,
    payload: error,
  };
};

export const UserLogout = (user_id) => {
  return async (dispatch, getState) => {
    const { error, result } = await deleteApi(`admin/logout/${user_id}`);
    if (!error) {
      sessionStorage.removeItem("user_id");
      sessionStorage.removeItem("token");
      return dispatch(logoutSuccess(result));
    }
    return dispatch(logoutFailure(error));
  };
};
