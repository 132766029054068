import { USER_FAILURE, USER_SUCCESS } from "../../constants/index";
import { onSuccess, onFailure } from "../common";

const initStat = { data: [], error: false, errorData: "", isLoading: true };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initStat, action) => {
  switch (action.type) {
    case USER_SUCCESS:
      return onSuccess(state, action);
    case USER_FAILURE:
      return onFailure(state, action);
    default:
      return { ...state };
  }
};
