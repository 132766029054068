import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import LoginReducer from "./login/login.reducer";
import LogoutReducer from "./login/logout.reducer";
import DashboardReducer from "./dashboard/dashboard.reducer";
import ProjectReducer from './project/project.reducer';
import languageReducer from './project/language.reducer';
import UserReducer from './user/user.reducer';
import AdminReducer from './admin/admin.reducer';
import ProjectRequestReducer from './project_request/project_request.reducer';
import TranslationReducer from './translation/tr-project.reducer';
import AnnotationReducer from './annotation/annotation-project.reducer';


const reducers = combineReducers({
  languageReducer: languageReducer,
  theme: Theme,
  auth: Auth,
  loginReducer: LoginReducer,
  logoutReducer: LogoutReducer,
  dashboardReducer: DashboardReducer,
  projectReducer: ProjectReducer,
  userReducer: UserReducer,
  adminReducer: AdminReducer,
  projectRequestReducer: ProjectRequestReducer,
  translationReducer: TranslationReducer,
  annotationReducer: AnnotationReducer,
});

export default reducers;
