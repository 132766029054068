import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { useHistory } from "react-router-dom";
import { UserLogout } from "redux/actions/logout/index";
import { useDispatch } from "react-redux";
// import AvatarStatus from 'components/shared-components/AvatarStatus';

const menuItem = [
  // {
  //   title: "Edit Profile",
  //   icon: EditOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Account Setting",
  //   icon: SettingOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Billing",
  //   icon: ShopOutlined,
  //   path: "/",
  // },
  // {
  //   title: "Help Center",
  //   icon: QuestionCircleOutlined,
  //   path: "/",
  // },
];

export const NavProfile = ({ signOut }) => {
  let dispatch = useDispatch();
  let history = useHistory();

  // const profileImg = "/img/avatars/thumb-1.jpg";
  let userId = sessionStorage.getItem("user_id");
  let name = sessionStorage.getItem("name");
  let email = sessionStorage.getItem("email")

  const UserLogOut = () => {
    dispatch(UserLogout(userId)).then(() => {
      sessionStorage.removeItem('token')
      history.push("/auth/login");
    });
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
        <Avatar style={{ color: '#fafafa', backgroundColor: '#0360EB' }}>{name? name.slice(0,1).toUpperCase(): email.slice(0,1).toUpperCase()}</Avatar>
          <div className="pl-2">
            <h4 className="mb-0">{name}</h4>
            <span className="text-muted">{email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={UserLogOut}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar style={{ color: '#fafafa', backgroundColor: '#0360EB' }}>{name? name.slice(0,1).toUpperCase(): email.slice(0,1).toUpperCase()}</Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
